import { ViewImage } from '@atproto/api/dist/client/types/app/bsky/embed/images';
import { useState } from 'react';
import { Link, MetaFunction } from 'react-router';
import { Bluesky } from '~/assets/icons';
import Arrow from '~/assets/images/arrow.svg?react';
import PostCard from '~/components/cards/PostCard';
import { renderIcon } from '~/components/robin/RobinIcons';
import { Button } from '~/components/ui/button';
import { timeAgo } from '~/services/helper';
import { Post } from '~/services/posts/Post';
import { generateMetaTags } from '~/services/utils/meta';

export const meta: MetaFunction = () => {
  return generateMetaTags({
    title: 'Custom Feed Generator',
  });
};

function getDateInPast(daysAgo: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  return date;
}

export default function Index() {
  const [posts, setPosts] = useState([
    new Post(
      '1',
      {
        id: '1',
        avatar: '/assets/avatar-6.png',
        handle: 'Mirafy_design',
        name: 'Miracle Franci',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create feeds using a simple search term 🤩',
      '',
      getDateInPast(1),
      [
        {
          url: '/assets/feat-search.png',
          type: 'image',
          thumb: '/assets/feat-search.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 12,
        likes: 48,
        reposts: 24,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
    new Post(
      '2',
      {
        id: '2',
        avatar: '/assets/avatar-2.png',
        handle: 'Marley_workman',
        name: 'Marley Workman',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create feeds using one or more hashtags 🤩',
      '',
      getDateInPast(21),
      [
        {
          url: '/assets/feat-hashtag.png',
          type: 'image',
          thumb: '/assets/feat-hashtag.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 8,
        likes: 32,
        reposts: 16,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
    new Post(
      '3',
      {
        id: '3',
        avatar: '/assets/avatar-4.png',
        handle: 'Angel_rosser',
        name: 'Angel Rosser',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create feeds based on your collected posts 🤩',
      '',
      getDateInPast(32),
      [
        {
          url: '/assets/feat-collections.png',
          type: 'image',
          thumb: '/assets/feat-collections.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 4,
        likes: 2,
        reposts: 8,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
    new Post(
      '4',
      {
        id: '4',
        avatar: '/assets/avatar-1.png',
        handle: 'Maria_kenter',
        name: 'Maria Kenter',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create feeds containing posts that a user is mentioned in them 🤩',
      '',
      getDateInPast(62),
      [
        {
          url: '/assets/feat-mentions.png',
          type: 'image',
          thumb: '/assets/feat-mentions.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 32,
        likes: 89,
        reposts: 19,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
    new Post(
      '5',
      {
        id: '5',
        avatar: '/assets/avatar-3.png',
        handle: 'Leo_carder',
        name: 'Leo Carder',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create feeds containing posts from specific users 🤩',
      '',
      getDateInPast(2),
      [
        {
          url: '/assets/feat-users.png',
          type: 'image',
          thumb: '/assets/feat-users.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 21,
        likes: 53,
        reposts: 7,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
    new Post(
      '6',
      {
        id: '6',
        avatar: '/assets/avatar-5.png',
        handle: 'Charlie_franci',
        name: 'Charlie Franci',
        meta: {},
      },
      '',
      '',
      'You can use RobinFeed to create advanced feeds using more than one criteria 🤩',
      '',
      getDateInPast(15),
      [
        {
          url: '/assets/feat-advanced.png',
          type: 'image',
          thumb: '/assets/feat-advanced.png',
          aspectRatio: {
            width: 372,
            height: 82,
          },
        },
      ],
      {
        comments: 16,
        likes: 64,
        reposts: 3,
        userLiked: false,
      },
      'bluesky',
      {},
    ),
  ]);
  const onPostLiked = (index: number) => {
    setPosts((prevPosts) => {
      const newPosts = [...prevPosts];
      newPosts[index].impressions.likes += 1;
      newPosts[index].impressions.userLiked = true;
      return newPosts;
    });
  };
  // const navigate = useNavigate();

  // useEffect(() => {
  //   navigate(Route.Home, { replace: true });
  // }, [navigate]);

  return (
    <div className="bg-rf-gray-f9">
      <div className="container mx-auto max-w-6xl px-4 py-8">
        <header className="mt-10 mb-16 flex flex-col items-center justify-between md:flex-row md:gap-4">
          <div className="mb-8 md:mb-0 w-full md:max-w-[370px] flex flex-col justify-center items-center gap-2">
            <h1 className="mb-4 text-5xl font-extrabold">RobinFeed</h1>
            <h2 className="mb-6 text-3xl font-regular">Custom Feed Generator</h2>
            <p className="mb-8 max-w-md text-gray-700 text-center text-xl">
              RobinFeed is a custom feed generator designed for decentralized social networks. It enables users to tailor their timelines with custom algorithms, providing a seamless, fully
              customizable experience that makes decentralized social networking more intuitive and engaging.
            </p>
            <div className="flex flex-row space-x-4 max-w-xs w-full justify-center">
              <Link to="https://bsky.app/">
                <Button variant="default" className="bg-transparent hover:bg-rf-gray-e7 focus:bg-rf-gray-e7 [&_path]:fill-[#0085FF] gap-1 w-full flex-grow">
                  <Bluesky fill="#0085FF" width="20px" height="20px" /> Signup With Bsky
                </Button>
              </Link>
              <Link to="/login">
                <Button variant="default" className=" w-full flex-grow gap-1">
                  {renderIcon('logo', 5)}
                  Sign-in
                </Button>
              </Link>
            </div>
          </div>
          <div className="w-full flex-grow flex justify-center translate-y-10 px-2">
            <img src="/assets/landing-hero.png" alt="RobinFeed App Screenshot" className="w-full rounded-lg max-w-[600px]" />
          </div>
        </header>

        <section className="mb-16 mt-20">
          <h2 className="mb-12 text-center text-3xl font-regular">What&apos;s the Use In RobinFeed!?</h2>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {posts.map((post, i) => {
              return (
                <PostCard
                  avatar={post.author.avatar}
                  displayName={post.author.name}
                  handle={post.author.handle}
                  timestamp={timeAgo(post.createdAt.toISOString())}
                  text={post.text}
                  images={
                    post.embeds.map((img) => ({
                      ...img,
                      fullsize: img.url,
                    })) as ViewImage[]
                  }
                  isFiltered={false}
                  key={post.id}
                  onAddToCollection={() => {}}
                  showMore={false}
                  showControllers={false}
                  showImpressions
                  post={post}
                  onLikeClicked={() => onPostLiked(i)}
                />
              );
            })}
          </div>
        </section>

        <section className="container mx-auto px-4 py-12 max-w-6xl">
          <h2 className="mb-12 text-center text-3xl font-regular">How To Use RobinFeed?</h2>

          <div className="flex flex-col md:flex-row items-center justify-between">
            <Link to="/login" className="md:w-1/3 flex flex-col items-center mb-8 md:mb-0">
              <div className="flex gap-2 justify-center items-center">
                <div className="bg-rf-primary border border-black rounded-full h-8 w-8 flex items-center justify-center">
                  <span className="text-black text-md">1</span>
                </div>
                <p className="font-regular text-xl">Login</p>
              </div>
              <div className="mt-6 w-48">
                <img src="/assets/step1.png" alt="Login Screen" className="w-full" />
              </div>
            </Link>

            <div className="rotate-90 md:rotate-0 h-[130px] md:h-auto flex justify-center items-center">
              <Arrow />
            </div>

            <Link to="/preset" className="md:w-1/3 flex flex-col items-center mb-8 md:mb-0">
              <div className="flex gap-2 justify-center items-center">
                <div className="bg-rf-primary border border-black rounded-full h-8 w-8 flex items-center justify-center">
                  <span className="text-black text-md">2</span>
                </div>
                <p className="font-regular text-xl">Create a feed</p>
              </div>
              <div className="mt-6 w-48">
                <img src="/assets/step2.png" alt="Create a feed" className="w-full" />
              </div>
            </Link>

            <div className="rotate-90 md:rotate-0 h-[130px] md:h-auto flex justify-center items-center">
              <Arrow />
            </div>

            <Link to="/home" className="md:w-1/3 flex flex-col items-center mb-8 md:mb-0">
              <div className="flex gap-2 justify-center items-center">
                <div className="bg-rf-primary border border-black rounded-full h-8 w-8 flex items-center justify-center">
                  <span className="text-black text-md">3</span>
                </div>
                <p className="font-regular text-xl">Start Browsing</p>
              </div>
              <div className="mt-6 w-48">
                <img src="/assets/step3.png" alt="Start Browsing" className="w-full" />
              </div>
            </Link>
          </div>
        </section>
      </div>
      <div className="bg-rf-gray-e7">
        <footer className="container mx-auto py-4 text-center text-rf-gray-75 text-sm">
          <p>© 2025 RobinFeed. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
}
