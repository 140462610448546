/* eslint-disable @typescript-eslint/no-explicit-any */

export type PostEmbed = {
  type: 'image' | 'video' | 'external';
  url: string;
  thumb?: string;
  title?: string;
  description?: string;
  alt?: string;
  aspectRatio?: {
    width: number;
    height: number;
    [k: string]: unknown;
  };
};
export class Post {
  public hydratedAt: Date;
  constructor(
    public id: string,
    public author: {
      id: string;
      name: string;
      handle: string;
      avatar: string;
      meta: any;
    },
    public uri: string,
    public publicUrl: string,
    public text: string,
    public textContent: string,
    public createdAt: Date,
    public embeds: PostEmbed[],
    public impressions: {
      likes: number;
      comments: number;
      reposts: number;
      userLiked: boolean;
    },
    public platform: string,
    public meta: any,
  ) {
    this.hydratedAt = new Date();
  }
}
